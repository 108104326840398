import React from "react"
import Welcome from "../components/sections/welcome"
import Card from "../components/shared/card"
const TimeLine = () => {
    return(
        <div path="/timeLine" className="container bottom time-line_card width-container_cards">
           <Welcome title="Important Dates"/>
           <Card handle_page="timeline" title="Next Steps">
               <ul>
                   <li>
                        <div className="flex pt-10">
                            <div className="linetime">
                                <div className="fcolor-white bg-orange rounded-full circle-red flex items-center justify-center">1</div>
                            </div>
                            <div className="py-2">
                                <p className="px-2 md:px-10 text-justify"><strong>December 9th, 2PM ET</strong>- Events calendar opens up. Be sure to book your event(s) once the calendar opens to ensure your preferred event day and time.</p>
                            </div> 
                        </div>
                   </li>
                   <li>
                   <div className="flex pt-7">
                        <div className="linetime">
                            <div className="fcolor-white bg-orange rounded-full circle-red flex items-center justify-center">2</div>
                        </div>
                        <div className="py-2">
                         <p className="px-2 md:px-10 text-justify"><strong>December 15th, 2PM ET </strong>- Deadline to submit creative assets required to populate your PDP on allure.shop.</p>
                            </div> 
                        </div>
                   </li>
                    <li>
                        <div className="flex pt-7">
                            <div className="linetime">
                                    <div className="fcolor-white bg-orange rounded-full circle-red flex items-center justify-center">3</div>
                            </div>
                            <div className="py-2">
                            <p className="px-2 md:px-10 text-justify"><strong>December 17th </strong>- Deadline for opening inventory to be received. Reminder, products received must have a completed ASN prior to shipping.</p>
                            </div> 
                        </div>
                    </li>
                    <li>
                        <div className="flex pt-10">
                            <div className="linetime">
                                    <div className="fcolor-white bg-orange rounded-full circle-red flex items-center justify-center">4</div>
                            </div>
                            <div className="py-2">
                             <p className="px-2 md:px-10 text-justify"><strong>January 2nd </strong>- Q1 Launch Day.</p>
                            </div> 
                        </div>
                    </li>
                  {/*
                      <li>
                        <div className="flex pt-10">
                            <div className="linetime">
                                    <div className="fcolor-white bg-orange rounded-full circle-red flex items-center justify-center">5</div>
                            </div>
                            <div className="py-2">
                             <p className="px-2 md:px-10 text-justify"><strong>December 1st </strong>- First payout, reflecting Net Sales between Oct 1-Nov 30.</p>
                            </div> 
                        </div>
                    </li>
                    <li>
                        <div className="flex pt-10">
                            <div className="linetime">
                                    <div className="fcolor-white bg-orange rounded-full circle-red flex items-center justify-center">6</div>
                            </div>
                            <div className="py-2">
                             <p className="px-2 md:px-10 text-justify"><strong>February 1st </strong>- Second payout, reflecting Net Sales between Dec 1-31.</p>
                            </div> 
                        </div>
                    </li>   
                  */}
               </ul>
           </Card>
        </div>
    )
}

export default TimeLine