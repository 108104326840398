import  { Fragment } from "react";
import Header from "./components/sections/header";
import NavList from "./components/shared/nav";
function App() {

  return (
    <Fragment>
      <Header/>
      <NavList/>
    </Fragment>
  );
}

export default App;

