import React, {useState, useEffect}from "react"

const Card = ({handle_page, number_card, title, children, enabled_check, id_check}) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleOnChange = () => {
        setIsChecked(!isChecked)
        window.localStorage.setItem(`${id_check}`, isChecked);
        window.localStorage.setItem(`${id_check}`, isChecked);
    };
    const checkbox = () =>{
        let check =  window.localStorage.getItem(`${id_check}`);
        if (check === 'false'){
            setIsChecked(true)
        } else{
            setIsChecked(false)
        }
    }
     useEffect(() => {
        checkbox();
     }, [])
    return(
        <React.Fragment>
        <div className={`card mx-auto flex flex-col px-9 md:px-10  py-10 justify-between relative ${isChecked ? 'opacity' : ''} `}>
          {number_card ?
            <div>
                <div className="card-number"></div>
                <div className="tring fcolor-white bg-black fcolor-white py-1 px-4">{number_card}</div> 
            </div> : ''
          }
           <h1 className="card-title f-bigCaslon-bold">{title}</h1>
           <div className="card-body f-monserrat-normal">
            {children}
           </div>
            {enabled_check ?    
            <div className="card-footer">
                <input type="checkbox" id={id_check} className="card-footer__checkbox mr-3" name="cards" checked={isChecked} onChange={handleOnChange} />
               <span className="relative top-span"><label for={id_check}>Check here when completed</label></span>
            </div> : ''}
        </div>
        </React.Fragment>
    )
}

export default Card