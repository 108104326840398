import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import TimeLine from "../../pages/timeLine";
import Upload from "../../pages/upload-assets";
import Landing from '../../pages/index';

const NavList = ()  => {
  return (
    <div className="todoLine md:container md:mx-auto flex">
    <Router>
        <nav className="nav flex justify-center items-end">
          <ul className="nav-list flex flex-row justify-evenly">
            <li className="nav-item">
            <NavLink
              exact
              className={`nav-item_link f-monserrat-bold inactive`} to="/"
            >
              To Do List
            </NavLink>
            </li>
            <li className="nav-item">
            <NavLink
              className={`nav-item_link f-monserrat-bold inactive `} to="/timeline"
            >
             Timeline
            </NavLink>
            </li>
            <li className="nav-item">
            <NavLink
              className={`nav-item_link f-monserrat-bold inactive`} to="/upload-assets"
            >
             Upload your Assets
            </NavLink>
            </li>
          </ul>
        </nav>
        <Switch>
          <Route path="/timeline" component={TimeLine} />
          <Route path="/upload-assets" component={Upload} />
          <Route path="/" component={Landing} />
        </Switch>
    </Router>
    </div>
  );
}

export default NavList
