import React from 'react'

export const Gmail = ({ width, height, fill, className }) => {
    return (
        <svg width={width} height={height} className={className} viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.01802 22.1721H6.72661V10.7496L0 5.71053V20.1567C0.000374262 21.2696 0.903843 22.1721 2.01802 22.1721Z" fill="#4285F4"/>
                <path d="M22.87 22.1721H27.579C28.6935 22.1721 29.597 21.2697 29.597 20.1563V5.71017L22.87 10.7496V22.1721Z" fill="#34A853"/>
                <path d="M22.87 2.0158V10.7496L29.5966 5.71053V3.02369C29.5966 1.35409 28.2414 0.000396729 26.5696 0.000396729C25.9146 0.000396729 25.2776 0.212742 24.7537 0.60528L22.87 2.0158Z" fill="#FBBC04"/>
                <path d="M6.72705 10.7496V2.01582L14.7984 8.06241L22.8697 2.01544V10.7496L14.7984 16.7962L6.72705 10.7496Z" fill="#EA4335"/>
                <path d="M0.000488281 3.02333V5.71017L6.7271 10.7496V2.01581L4.84344 0.604918C3.5062 -0.396991 1.60907 -0.126327 0.606044 1.20943C0.212695 1.73281 0.000488281 2.36947 0.000488281 3.02333Z" fill="#C5221F"/>
        </svg>
    )
}
export const Outlook = ({ width, height, fill, className }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M25.0198 1.75659H9.97783C9.30392 1.75659 8.74939 2.31321 8.74939 2.98966V4.39124L17.2275 7.02615L26.2482 4.39124V2.98966C26.2482 2.31321 25.6937 1.75659 25.0198 1.75659Z" fill="#0364B8"/>
            <path d="M27.692 15.286C27.8201 14.8817 27.9225 14.4695 27.9982 14.052C27.9983 13.8432 27.8871 13.6496 27.7069 13.5453L27.6955 13.5391L27.692 13.5375L18.2093 8.11511C18.1684 8.08854 18.126 8.06414 18.0825 8.04217C17.7153 7.85948 17.2834 7.85948 16.9162 8.04217C16.8727 8.06414 16.8303 8.08841 16.7893 8.11511L7.30671 13.5375L7.30314 13.5391L7.29169 13.5453C7.1116 13.6496 7.00036 13.8432 7.00049 14.052C7.07622 14.4695 7.17854 14.8817 7.30671 15.286L17.3615 22.6676L27.692 15.286Z" fill="#0A2767"/>
            <path d="M20.9987 4.39127H14.874L13.1057 7.02617L14.874 9.66082L20.9987 14.9302H26.2483V9.66082L20.9987 4.39127Z" fill="#28A8EA"/>
            <path d="M8.74951 4.39124H14.8741V9.66067H8.74951V4.39124Z" fill="#0078D4"/>
            <path d="M20.9987 4.39124H26.2483V9.66067H20.9987V4.39124Z" fill="#50D9FF"/>
            <path d="M20.9987 14.9302L14.8741 9.6608H8.74951V14.9302L14.8741 20.1998L24.3515 21.7525L20.9987 14.9302Z" fill="#0364B8"/>
            <path d="M14.874 9.6608H20.9986V14.9302H14.874V9.6608Z" fill="#0078D4"/>
            <path d="M8.74951 14.9302H14.8741V20.1997H8.74951V14.9302Z" fill="#064A8C"/>
            <path d="M20.9987 14.9302H26.2483V20.1997H20.9987V14.9302Z" fill="#0078D4"/>
            <path d="M17.6661 22.1476L7.34692 14.5948L7.77914 13.8316C7.77914 13.8316 17.1804 19.2064 17.3238 19.2873C17.4427 19.3352 17.5763 19.3313 17.6923 19.2766C17.826 19.2011 27.2571 13.8052 27.2571 13.8052L27.6911 14.5684L17.6661 22.1476Z" fill="#0A2767" fillOpacity="0.498039"/>
            <path d="M27.7069 14.5587L27.6955 14.5658L27.6929 14.5675L18.2103 19.9899C17.8277 20.2375 17.3431 20.2678 16.9329 20.0697L20.2357 24.5145L27.4576 26.0928V26.0964C27.798 25.8492 28.0001 25.452 28 25.0301V14.0521C28.0001 14.2608 27.8888 14.4545 27.7087 14.5587H27.7069Z" fill="#1490DF"/>
            <path d="M27.9982 25.0301V24.382L19.2636 19.3865L18.2093 19.989C17.8269 20.2366 17.3422 20.267 16.932 20.0688L20.2349 24.5137L27.4567 26.0919V26.0954C27.7972 25.8481 27.9991 25.4511 27.9991 25.0292L27.9982 25.0301Z" fill="black" fillOpacity="0.047059"/>
            <path d="M27.9546 25.3665L18.38 19.8915L18.2093 19.989C17.8269 20.2366 17.3422 20.267 16.932 20.0689L20.2349 24.5138L27.4567 26.0919V26.0955C27.7017 25.9171 27.878 25.6592 27.9554 25.3656L27.9546 25.3665Z" fill="black" fillOpacity="0.098039"/>
            <path d="M7.30575 14.5702V14.5614H7.29709L7.27075 14.5439C7.10097 14.439 6.99788 14.2521 6.99953 14.0521V25.0319C6.99953 25.7536 7.59122 26.3475 8.31031 26.3475H26.6859C26.7951 26.3464 26.904 26.3317 27.0096 26.3036C27.0644 26.294 27.1175 26.2762 27.1672 26.2509C27.1857 26.249 27.2036 26.243 27.2196 26.2333C27.2911 26.2039 27.3588 26.1656 27.4208 26.1191L27.4558 26.0928L7.30575 14.5702Z" fill="#28A8EA"/>
            <path d="M15.749 21.6638V7.31947C15.7471 6.6779 15.2218 6.15066 14.5827 6.14874H8.77662V12.6969L7.30573 13.5382L7.30229 13.54L7.29097 13.5462C7.11075 13.6507 6.99951 13.8442 6.99951 14.0528V22.838V22.8344H14.5827C15.2218 22.8326 15.7471 22.3053 15.749 21.6638Z" fill="black" fillOpacity="0.098039"/>
            <path d="M14.8741 22.542V8.19764C14.8722 7.5562 14.3468 7.02896 13.7078 7.02692H8.77662V12.6968L7.30573 13.5382L7.30229 13.54L7.29097 13.5461C7.11075 13.6507 6.99951 13.8442 6.99951 14.0528V23.7163V23.7127H13.7078C14.3468 23.7108 14.8722 23.1836 14.8741 22.542ZM14.8741 20.7855V8.19764C14.8722 7.5562 14.3468 7.02896 13.7078 7.02692H8.77662V12.6968L7.30573 13.5382L7.30229 13.54L7.29097 13.5461C7.11075 13.6507 6.99951 13.8442 6.99951 14.0528V21.9597V21.9563H13.7078C14.3468 21.9543 14.8722 21.4271 14.8741 20.7855ZM13.9991 20.7855V8.19764C13.9972 7.5562 13.472 7.02896 12.8329 7.02692H8.77662V12.6968L7.30573 13.5382L7.30229 13.54L7.29097 13.5461C7.11075 13.6507 6.99951 13.8442 6.99951 14.0528V21.9597V21.9563H12.8329C13.472 21.9543 13.9972 21.4271 13.9991 20.7855Z" fill="black" fillOpacity="0.2"/>
            <path d="M1.16633 7.02615H12.8319C13.4717 7.02615 13.9982 7.55454 13.9982 8.19676V19.9065C13.9982 20.5487 13.4717 21.0772 12.8319 21.0772H1.16633C0.526527 21.0772 0 20.5487 0 19.9065V8.19676C0 7.55454 0.526527 7.02615 1.16633 7.02615Z" fill="#0078D4"/>
            <path d="M3.38333 11.8283C3.69324 11.1654 4.19367 10.6105 4.81998 10.2352C5.514 9.83634 6.30424 9.63768 7.10364 9.66081C7.84398 9.64446 8.57478 9.83289 9.21573 10.2053C9.81887 10.5658 10.3047 11.0947 10.6138 11.7273C10.9506 12.4244 11.1186 13.1914 11.1038 13.966C11.1202 14.7755 10.9473 15.5778 10.5991 16.3083C10.283 16.9633 9.7826 17.5112 9.15973 17.8839C8.49384 18.268 7.7358 18.4616 6.96797 18.4433C6.21158 18.4613 5.46462 18.2708 4.80866 17.8926C4.2008 17.5314 3.70915 17.002 3.393 16.368C3.05408 15.6809 2.88417 14.9222 2.89778 14.1557C2.88277 13.353 3.04911 12.5574 3.38422 11.8283H3.38333ZM4.91442 15.5679C5.07975 15.987 5.36013 16.3507 5.72298 16.6164C6.0922 16.876 6.53486 17.0099 6.9854 16.9986C7.46548 17.0176 7.9388 16.8795 8.33373 16.6051C8.69213 16.3394 8.96513 15.9737 9.11862 15.5538C9.2912 15.0857 9.37622 14.5896 9.36973 14.0906C9.37507 13.5873 9.29515 13.0865 9.13351 12.61C8.99097 12.181 8.72777 11.8023 8.37573 11.5201C7.99124 11.2319 7.51918 11.0855 7.03975 11.1056C6.5794 11.0936 6.12682 11.2282 5.74742 11.4902C5.37782 11.7569 5.0912 12.1236 4.92142 12.5476C4.54597 13.5184 4.5438 14.5957 4.91531 15.5679H4.91442Z" fill="white"/>
        </svg>
        
    )
}
export const Slack = ({ width, height, fill, className }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M6.5406 0.0119934C5.55651 0.0119934 4.75243 0.816066 4.75243 1.80016C4.75243 2.78425 5.55651 3.58832 6.5406 3.58832H8.32876V1.80016C8.32876 0.816066 7.53669 0.0119934 6.5406 0.0119934ZM6.5406 4.77643H1.78816C0.804073 4.77643 0 5.5805 0 6.56459C0 7.54868 0.804073 8.35275 1.78816 8.35275H6.5526C7.53669 8.35275 8.34076 7.54868 8.34076 6.56459C8.32876 5.5805 7.53669 4.77643 6.5406 4.77643Z" fill="#36C5F0"/>
        <path d="M17.8576 6.5646C17.8576 5.58051 17.0535 4.77644 16.0694 4.77644C15.0854 4.77644 14.2813 5.58051 14.2813 6.5646V8.35276H16.0694C17.0535 8.36476 17.8576 7.56069 17.8576 6.5646ZM13.0932 6.5646V1.78816C13.0932 0.804073 12.2891 0 11.305 0C10.3209 0 9.51685 0.804073 9.51685 1.78816V6.5646C9.51685 7.54869 10.3209 8.35276 11.305 8.35276C12.3011 8.36476 13.0932 7.56069 13.0932 6.5646Z" fill="#2EB67D"/>
        <path d="M11.305 17.9056C12.2891 17.9056 13.0932 17.1016 13.0932 16.1175C13.0932 15.1334 12.2891 14.3293 11.305 14.3293H9.51685V16.1175C9.51685 17.1016 10.3209 17.9056 11.305 17.9056ZM11.305 13.1292H16.0694C17.0535 13.1292 17.8576 12.3251 17.8576 11.341C17.8576 10.3569 17.0535 9.55287 16.0694 9.55287H11.305C10.3209 9.55287 9.51685 10.3569 9.51685 11.341C9.51685 12.3251 10.3209 13.1292 11.305 13.1292Z" fill="#ECB22E"/>
        <path d="M0 11.341C0 12.3251 0.804073 13.1292 1.78816 13.1292C2.77225 13.1292 3.57633 12.3251 3.57633 11.341V9.55287H1.78816C0.792072 9.55287 0 10.3569 0 11.341ZM4.76444 11.341V16.1175C4.76444 17.1016 5.56851 17.9056 6.5526 17.9056C7.53669 17.9056 8.34076 17.1016 8.34076 16.1175V11.341C8.34076 10.3569 7.53669 9.55287 6.5526 9.55287C5.55651 9.55287 4.76444 10.3569 4.76444 11.341Z" fill="#E01E5A"/>
        </svg>
        
        
        
    )
}