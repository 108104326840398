import React from "react"
const Welcome = ({title ,children}) => {
    return(
        <div className="welcome-container container px-3 mx-auto my-20">
            <div className="wecolme-body text-center">
                <h1 className="welcome-title f-bigCaslon-bold pb-5">{title}</h1>
                {children}
            </div>
        </div>
    )
}

export default Welcome