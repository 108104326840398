import React from "react"
import Welcome from "../components/sections/welcome"
import Card from "../components/shared/card"
const Upload = () => {
    return(
        <div className="upload-assets  container bottom width-container_cards">
           <Welcome title="UPLOAD ASSETS"/>
           <Card handle_page="upload-assets" title="Deadline for submission: December 15th, 2021">
                <p className="px-1 md:px-10 pt-7 pb-5 text-justify">In order for us to launch your products at the Allure Store, we need to collect assets from you. Please refer to the asset collection kit here: 
                <a className="underline text-blue-700" href="https://docsend.com/view/wvgbqjjcm47ta74c"> (https://docsend.com/view/wvgbqjjcm47ta74c)
                    </a> for an overview of what we need.</p>
                <p className="px-1 md:px-10 pb-7 text-justify">Download the folder below and fill it with your content. Be sure to follow instructions.</p>
                <a className="h-full w-full bg-black  fcolor-white px-4 py-2 md:ml-10 my-10 rounded-md mt-10 button-hover" href='../../Allure_Store _Asset_Upload.zip' download="Allure Store Asset Upload Template.zip" >Download Folder Structure</a>
           </Card>
           <h2 className="welcome-title f-bigCaslon-normal pb-5 sub-title text-center pt-4">When you have placed all necessary assets into your folder, please follow the link below to submit your assets.</h2>
           <div className="card mx-auto fill-card bg-image">
               <div className="head text-center pt-16">
                    <h3 className="f-bigCaslon-bold">Upload an asset to</h3>
                    <h3 className="f-bigCaslon-bold">Allure Store's Brandfolder</h3>    
                </div> 
                    <div className="iframe flex items-center justify-center">
                        <a className="bg-black fcolor-white px-5 py-2 my-7 rounded-xl button-hover f-monserrat-normal" href='https://brandfolder.com/guest_upload/b55558frfwj4srpv76pbgn' target="_blank">Upload assets here</a>
                    </div>
                <div className="head text-center pt-7 text-xs">
                    <h3 className="f-bigCaslon-bold">What is Brandfolder?</h3>
                    <p className="f-monserrat-normal px-3">Store, share, and showcase your assets in one place with Brandfolder, the world's most</p>
                    <p className="f-monserrat-normal px-3">powerfully simple digital asset management sotfware</p>
                    <p className="text-blue-700">Learn more...</p>    
                </div> 
           </div>
        </div>
    )
}

export default Upload