import React from "react";
import { Link } from "react-router-dom";
import { Outlook, Gmail, Slack } from "../components/icons";
import Welcome from "../components/sections/welcome";
import Card from "../components/shared/card";
const Landing = () => {
  return (
    <div className="landing container  bottom width-container_cards">
      <Welcome title="Welcome to the Allure Store!">
        <p className="welcome-text f-monserrat-normal">
          We’re very pleased to be partnering with you for 
          an exciting Q1. There are a few tasks to onboard 
          your brand and product(s) into the Allure Store 
          experience and to prepare you for success.
        </p>
        <br></br>
        <p className="welcome-text f-monserrat-normal">
          Please use the tabs to toggle between sections. 
          On a computer, they are located on the left hand side. 
          On a mobile device, they are located at the top.
        </p>
      </Welcome>
      <Card
        handle_page="landing"
        number_card="1"
        title="Whitelist our domain so we don’t end up in spam (@allure.shop) "
        enabled_check={true}
        id_check="checkbox-1"
      >
        <div className="landing-card__description py-5">
          It’s important that we’re able to communicate important information to
          you during your time at the Allure Store. This includes
          logistics-based communication, success metrics, event management, tips
          from our beauty guides, and much more.
        </div>
        <h2 className="landing-card__title mt-5 f-monserrat-bold pb-3">
          <strong>Here’s how to whitelist our domain:</strong>
        </h2>
        <div className="flex f-monserrat-bold">
          <Outlook width="20" height="20" className="landing-card__icon mr-3" />
          Outlook
        </div>
        <p className="py-4">
          Simply add the <strong>onboarding@allure.shop</strong> email address
          to your contacts.
        </p>
        <div className="flex f-monserrat-bold">
          <Gmail width="20" height="20" className="landing-card__icon mr-3" />
          Gmail
        </div>
        <p className="py-3">
          Click the cog icon in the top-right corner, and then Settings
        </p>
        <ol className="landing-card-list list-outside list pl-10">
          <li className="py-2">
            Click on Filters and then Create a new filter
          </li>
          <li className="py-2">
            Either
            <ul>
              <li className="pl-2 py-3 inline-flex">
                <strong className="circle fcolor-orange px-3">● </strong> enter
                the domain of the email you want to whitelist in the From field
                or
              </li>
              <li className="pl-2 py-3 inline-flex">
                <strong className="circle fcolor-orange px-3">● </strong> enter
                the email address of the online group you want to whitelist in
                the To field{" "}
              </li>
            </ul>
          </li>
          <li className="py-2">Click Create filter with this search.</li>
          <li className="py-2">
            In the box headed When a message arrives that matches this search
            select Never send it to spam.
          </li>
          <li className="py-2">Click the Create filter button.</li>
        </ol>
      </Card>
      <Card
        handle_page="landing"
        number_card="2"
        title="Join our Slack Channel (Optional)"
        enabled_check={true}
        id_check="checkbox-2"
      >
        <p className="py-3">
          The Allure Store slack channel is open to all brand partners to
          improve overall access; facilitate collaboration; quickly share
          updates/concerns; and provide a courtesy line (DMs) to the Allure
          Store team members dedicated to brand success. The biggest benefit to
          joining this channel is to stay in the know of regular,
          non-actionable, store-wide updates.{" "}
        </p>
        <a
          href="https://allurestorebr-0n76265.slack.com/join/shared_invite/zt-zebl80up-IFBpIo400HN57p_7BE~Y6A#/shared-invite/email"
          target="_blank"
          className="bg-black inline-flex fcolor-white px-5 py-2 my-7 rounded-xl button-hover"
        >
          <Slack width="18" height="18" className="landing-card__icon mr-3" />
          Join our Slack Channel
        </a>
      </Card>
      <Card
        handle_page="landing"
        number_card="3"
        title="Team"
        enabled_check={true}
        id_check="checkbox-3"
      >
        <p className="pt-5">
          Assign contacts from your organization for the following communication and access.
          If there is just one point of contact for all activities
           please include that person in each response field.
        </p>

        <h2 className="landing-card__title mt-5 f-monserrat-bold pb-3">
          Accounts Receivable:
        </h2>
        <p>
          {" "}
          This person will receive access to the payout portal to set up your sales payouts from the Allure Store.
        </p>

        <h2 className="landing-card__title mt-5 f-monserrat-bold pb-3">
          Activation Dashboard:
        </h2>
        <p>
          This person will receive access to the everyday analytics dashboard, 
          where you’re able to monitor your sales & traffic data, book events, 
          and reference handy program materials. {" "}
        </p>

        <h2 className="landing-card__title mt-5 f-monserrat-bold pb-3">
          Main POC
        </h2>
        <p>Responsible for all aspects of your Allure Store activation</p>
        <a
          className="h-full bg-black inline-block fcolor-white px-12 py-2 my-7 rounded-xl button-hover"
          href=" https://form.jotform.com/212356475831054"
          target="_blank"
        >
          Click here
        </a>
      </Card>
      <Card
        handle_page="landing"
        number_card="4"
        title="Inventory: Prepare your Advanced Shipping Notice (ASN) form"
        enabled_check={true}
        id_check="checkbox-4"
      >
        <p className="pt-5">
          Let us know how much inventory you’ll be sending for each of your
          SKUs, including samples and collateral items. This also includes
          variants, where applicable and where previously approved. You must use
          the unique, Allure Store-issued Product IDs (PIDs) assigned to your
          SKUs, which were assigned to you by email, as you complete this form.
        </p>

        <h2 className="landing-card__title mt-5 f-monserrat-bold pb-3">
          Our recommendations for opening inventory shipments, per SKU:
        </h2>
        <h2 className="landing-card__title mt-5 f-monserrat-bold pb-3">
          Full-size units:
        </h2>
        <ul>
          <li className="pl-4 py-2">
            <strong className="circle fcolor-orange">●</strong>{" "}
            <span className="f-monserrat-bold">
              Floor/Display (this includes testers):{" "}
            </span>
            4-8, less for larger items, more for smaller items
          </li>
          <li className="pl-4 py-2">
            <strong className="circle fcolor-orange">●</strong>
            <span className="f-monserrat-bold">Sellable Inventory: </span>50
          </li>
          <li className="pl-4 py-2">
            <strong className="circle fcolor-orange">●</strong>
            <span className="f-monserrat-bold">
              Variant Shades/Sizes if Applicable:{" "}
            </span>
            15 per variant{" "}
          </li>
          <li className="pl-4 py-2">
            <strong className="circle fcolor-orange">●</strong>
            <span className="f-monserrat-bold">Staff Gratis & Training: </span>
            20
          </li>
        </ul>
        <h2 className="landing-card__title mt-5 f-monserrat-bold pb-3">
          Collateral items:
        </h2>
        <ul>
          <li className="pl-4 py-2">
            <strong className="circle fcolor-orange">●</strong>{" "}
            <span className="f-monserrat-bold">
              Not greater than quantity of sellable inventory:{" "}
            </span>
            50
          </li>
        </ul>
        <h2 className="landing-card__title mt-5 f-monserrat-bold pb-3">
          Sample- or trial-size units:
        </h2>
        <ul>
          <li className="pl-4 py-2">
            <strong className="circle fcolor-orange">●</strong>{" "}
            <span className="f-monserrat-bold">
              Samples for general sampling:{" "}
            </span>
            100-300
          </li>
        </ul>

        <a
          className="h-full bg-black inline-block fcolor-white px-12 py-2 my-7 rounded-xl button-hover"
          href="https://form.jotform.com/211294960366056"
          target="_blank"
        >
          Click here
        </a>
      </Card>
      <Card
        handle_page="landing"
        number_card="5"
        title="Prepare your creative assets for your Allure.shop PDP"
        enabled_check={true}
        id_check="checkbox-5"
      >
        <p className="pt-6">
          Refer to the{" "}
          <a
            className="f-monserrat-bold underline text-blue-700"
            href="https://docsend.com/view/wvgbqjjcm47ta74c"
            target="_blank"
          >
            asset collection kit
          </a>{" "}
          when preparing/gathering your creative assets. 
          Visit the Upload Your Assets page and download the folder structure to get started. 
        </p>

        <p className="pt-6">Submission deadline: December 15, 2021.</p>
        <Link
          className="h-full bg-black inline-block fcolor-white px-12 py-2 my-7 rounded-xl button-hover"
          to="/upload-assets"
        >
          Submit Assets
        </Link>
      </Card>
      <Card
        handle_page="landing"
        number_card="6"
        title="Tips to get the most out of the Allure Store (please take us up on all of them)"
        enabled_check={true}
        id_check="checkbox-6"
      >
        <div className="cm-card">
          <p className="pt-6 list-item-check">
            <span className="f-monserrat-bold"> Book an Event
            </span> - Brands who conduct in-store programming have success at the Allure Store. 
            Event calendar opens on{" "}
            <strong>December 9, 2021 at 2pm ET. </strong>  Events are booked on a first come, 
            first serve basis. Brands can book three (3) events per quarter.
          </p>
          <a
            href="https://allurestoreevents.as.me/schedule.php#"
            target="_blank"
            className="bg-black inline-flex fcolor-white px-12 py-2 my-2 rounded-xl button-hover"
          >
            Event Cal
          </a>

          <p className="pt-6 list-item-check">
            <span className="f-monserrat-bold">Train our Beauty Guides</span>{" "}
            - Our beauty guides take the time to learn your product. Book a 30
            minute training session with them.
          </p>
          <a
            href="https://app.squarespacescheduling.com/schedule.php?owner=22998524"
            target="_blank"
            className="bg-black inline-flex fcolor-white px-12 py-2 my-2 rounded-xl button-hover"
          >
            Training Cal
          </a>

          <p className="pt-6 list-item-check">
            Opt-In to have your products included in special promotions - From
            influencer gifting to in-store promotions, your participation is
            recommended.
          </p>
          <a
            href="https://form.jotform.com/212356336657056"
            target="_blank"
            className="bg-black inline-flex fcolor-white px-12 py-2 my-2 rounded-xl button-hover"
          >
            Go to Opt-Ins
          </a>

          <p className="pt-6 list-item-check">
            <strong>FAQs</strong> - Thoroughly review our FAQs for all
            information regarding the program.
          </p>
          <p className="py-2">
            <a
              className="text-blue-700"
              href="https://docsend.com/view/ahsuww42ibsygm6h"
              target="_blank"
            >
              Onboarding FAQs
            </a>
          </p>
          <p className="pb-2">
            <a
              className="text-blue-700"
              href="https://docsend.com/view/ybk48iywxdyzxvcv"
              target="_blank"
            >
              General FAQs
            </a>
          </p>
          <p className="pb-2">
            <a
              className="text-blue-700"
              href="https://docsend.com/view/x6j32ak34wnpcvzw"
              target="_blank"
            >
              Event Guide
            </a>
          </p>
          <p className="pb-2">
            <a
              className="text-blue-700"
              href="https://docsend.com/view/4vi6fig95emr4uxr"
              target="_blank"
            >
              Social Media Guide
            </a>
          </p>
          <p className="pb-6">
            <a
              className="text-blue-700"
              href="https://docsend.com/view/hswhijt8v4mpafu6"
              target="_blank"
            >
              Influencer Seeing FAQ
            </a>
          </p>
        </div>
      </Card>
    </div>
  );
};

export default Landing;
